<template>
  <v-container class="table-filters" fluid>
    <v-row>
      <v-col cols="12" sm="6">
        <v-select
          v-model="selectedFiltersLocationTypeIds"
          :items="availableLocationTypeFilters"
          :label="$trans('service_location')"
          multiple
          small-chips
          deletable-chips
          outlined
          clearable
          hide-details
          :disabled="disabled"
          dense
          :menu-props="{ offsetY: true }"
        >
          <template #item="data">
            <div class="d-flex align-center pointer my-2">
              <span class="text-break cd-v-select-line-height">{{
                data.item.text
              }}</span>
            </div>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" sm="6">
        <services-field
          v-model="selectedFiltersServiceIds"
          small-chips
          multiple
          deletable-chips
          dense
          return-ids
          keep-menu-open-on-select
        />
      </v-col>
      <v-col cols="12" sm="12">
        <v-text-field
          v-model="selectedFiltersSearch"
          :label="$trans('search')"
          :placeholder="$trans('autocomplete_hint')"
          append-icon="$magnify"
          hide-details
          outlined
          single-line
          clearable
          :disabled="disabled"
          dense
        />
      </v-col>
    </v-row>
    <v-row class="my-4" justify="center">
      <v-col class="d-flex align-center justify-center" cols="12" md="3" sm="6">
        <v-btn
          color="primary"
          outlined
          small
          :disabled="disabled"
          @click="clearFilters"
        >
          {{ $trans("clear_filters") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import sharedListFilters from "@/calendesk/mixins/sharedListFilters";
import ServicesField from "@/lib/calendesk-js-library/components/ServicesField.vue";

export default {
  name: "LocationFilter",
  components: { ServicesField },
  mixins: [sharedListFilters],
  data() {
    return {
      cacheId: "location-list-filters",
      selectedFiltersLocationTypeIds: [],
      selectedFiltersSearch: null,
      selectedFiltersServiceIds: [],
    };
  },
  computed: {
    filters() {
      return {
        selectedFiltersLocationTypeIds: this.selectedFiltersLocationTypeIds,
        selectedFiltersServiceIds: this.selectedFiltersServiceIds,
        selectedFiltersSearch: this.selectedFiltersSearch,
      };
    },
  },
  methods: {
    getData() {
      const data = {};

      if (
        this.selectedFiltersLocationTypeIds &&
        this.selectedFiltersLocationTypeIds.length > 0
      ) {
        data.type_ids = this.selectedFiltersLocationTypeIds.join(",");
      }

      if (
        this.selectedFiltersServiceIds &&
        this.selectedFiltersServiceIds.length > 0
      ) {
        data.service_ids = this.selectedFiltersServiceIds.join(",");
      }

      if (this.selectedFiltersSearch) {
        data.query = this.selectedFiltersSearch;
      }

      return data;
    },
  },
};
</script>
