<template>
  <tr
    :key="item.id"
    :class="{
      'v-data-table__mobile-table-row': !$vuetify.breakpoint.mdAndUp,
    }"
    class="table-row"
    @mouseleave="isHovered = false"
    @mouseover="isHovered = true"
    @click.stop="clickRow(item)"
  >
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__checkbox-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="pt-2 text-center"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      ></div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <v-simple-checkbox
          :ripple="false"
          :value="isSelected"
          color="primary"
          @input="selectOnChange($event)"
        />
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__5-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="pt-2 text-center"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        <div>
          {{ $trans("id") }}
        </div>
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <div class="text-left">
          <div>{{ item.id }}</div>
        </div>
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__70-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        {{ $trans("service_location") }}
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <div class="d-flex align-center">
          <v-icon
            v-if="getIconForType(item.type)"
            class="mr-1"
            color="primary"
            size="20"
          >
            {{ getIconForType(item.type) }}
          </v-icon>
          <img
            v-else
            height="15"
            class="mr-2 ml-1"
            :src="getImageForType(item.type)"
            :alt="$trans('service_location_' + item.type)"
          />
          <div>
            <span v-if="item.name">{{ item.name }}</span>
            <span v-else>{{ $trans("service_location_" + item.type) }}</span>
            <template v-if="item.data"> <br />{{ item.data }} </template>
          </div>
        </div>
        <service-chips
          v-if="item.service_ids && item.service_ids.length > 0"
          :service-ids="item.service_ids"
        />
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__manage-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div class="row-list-buttons white rounded mr-1 mt-2">
        <location-list-buttons :is-hovered="isHovered" :location="item" />
      </div>
    </td>
  </tr>
</template>
<script>
import itemRowActions from "@/calendesk/mixins/itemRowActions";
import LocationListButtons from "@/views/dashboard/pages/Settings/components/LocationListButtons.vue";
import locationActions from "@/calendesk/mixins/locationActions";
import ServiceChips from "@/components/ServiceChips.vue";

export default {
  components: {
    ServiceChips,
    LocationListButtons,
  },
  mixins: [itemRowActions, locationActions],
};
</script>
