<template>
  <list-buttons
    :is-hovered="isHovered"
    :extra-actions="extraActions"
    :primary-actions="primaryActions"
    @fireAction="fire"
  />
</template>
<script>
import ListButtons from "@/components/ListButtons.vue";
import locationActions from "@/calendesk/mixins/locationActions";

export default {
  name: "LocationListButtons",
  components: { ListButtons },
  mixins: [locationActions],
  props: {
    location: {
      type: Object,
      required: true,
    },
    isHovered: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    extraActions() {
      const actions = [];

      if (this.loggedUserCanEditServices) {
        actions.push({
          action: "edit",
          color: "light",
          icon: "$pencil",
          title: this.$trans("edit"),
        });

        actions.push({
          action: "delete",
          color: "error",
          icon: "$trash-default",
          title: this.$trans("delete"),
        });
      }

      return actions;
    },
    primaryActions() {
      const actions = [];

      if (this.loggedUserCanEditServices) {
        actions.push({
          action: "edit",
          color: "light",
          icon: "$pencil",
          title: this.$trans("edit"),
        });
      }

      return actions;
    },
  },
  methods: {
    fire(action) {
      switch (action) {
        case "edit":
          this.handleEditLocation(this.location);
          break;
        case "delete":
          this.handleDeleteLocations([this.location]);
          break;
      }
    },
  },
};
</script>
